import { render, staticRenderFns } from "./CzxpRewardEffect.vue?vue&type=template&id=5977dba8&scoped=true&"
import script from "./CzxpRewardEffect.vue?vue&type=script&lang=js&"
export * from "./CzxpRewardEffect.vue?vue&type=script&lang=js&"
import style0 from "./CzxpRewardEffect.vue?vue&type=style&index=0&id=5977dba8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5977dba8",
  null
  
)

export default component.exports